var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "player__wrapper",
      on: {
        click: function($event) {
          $event.stopPropagation()
        }
      }
    },
    [
      _c("div", { staticClass: "player__controls" }, [
        _c("div", { staticClass: "player__play", on: { click: _vm.play } }, [
          _vm.isPlaying
            ? _c("i", { staticClass: "ri-pause-fill" })
            : _c("i", { staticClass: "ri-play-fill" })
        ])
      ]),
      _c(
        "div",
        { staticClass: "player__timeline", on: { click: _vm.changeTimeline } },
        [
          _c("div", {
            staticClass: "player__progress",
            style: { width: _vm.progressWidth }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }