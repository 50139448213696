<template>
  <div class="popup__wrapper">
    <div class="popup__header">
      <div class="popup__header-title">Bids Activity</div>
      <button class="icon-button icon-button--round" @click="$modal.hide('AuctionPopup')">
        <i class="ri-close-circle-fill"></i>
      </button>
    </div>
    <div class="bids__wrapper">
      <div v-if="isLoading" class="bids__skeleton">
        <Skeleton v-for="i of 3" :key="i" :height="'73px'" />
      </div>
      <div v-else class="bids__list">
        <div v-for="bid of bids" :key="bid.id" class="bids__item">
          <div class="bids__date is-mobile">
            {{ moment(bid.createdAt).format('DD MMM HH:mm') }}
          </div>
          <div class="bids__main">
            <div class="bids__creator">
              <span>Bid placed by </span>
              <span>{{ bid.authorBusinessName }} </span>
              <span v-if="bid.authorNickName">({{ bid.authorNickName }}) </span>
              <span v-if="bid.authorFathersName">{{ bid.authorFathersName }}’ </span>
              <span>{{ bid.authorFirstName }} </span>
              <span>{{ bid.authorMiddleInitial }} </span>
              <span>{{ bid.authorLastName }} </span>
            </div>
            <div class="bids__date">
              {{ moment(bid.createdAt).format('DD MMM HH:mm') }}
            </div>
          </div>

          <div class="bids__price">$ {{ bid.bid }}</div>
          <button
            class="button button--green"
            :class="{'button--green--active': bid.isApproved, 'button--green--disabled': post.postType === 'sold'}"
            @click="approve(bid)"
          >
            <span v-if="post.postType === 'sold' && bid.isApproved">Approved</span>
            <span v-else>Approve</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import moment from 'moment'
import Skeleton from '@/components/Skeleton'
import Popup from './ApprovePopup'

export default {
  name: 'Auction',
  components: {Skeleton},
  props: {
    post: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      moment,
      postID: null,
      isLoading: false,
      isLoad: true
    }
  },
  computed: {
    ...mapState({
      bids: s => s.community.auction.bidsList
    })
  },
  async created() {
    this.isLoading = true
    await this.fetch(this.post.id)
    this.isLoading = false
  },
  methods: {
    ...mapActions({
      fetch: 'community/auction/fetchBids',
      approveBid: 'community/auction/approveBid',
      fetchPost: 'community/posts/fetchPost',
      markAsSold: 'community/posts/markAsSold'
    }),
    ...mapMutations({
      markAsSold: 'community/posts/markAsSold'
    }),
    async approve(bid) {
      if (!this.isLoad) return
      this.isLoad = false
      const resp = await this.approveBid({postID: this.post.id, id: bid.id})
      this.isLoad = true
      if (resp.data.success) this.markAsSold({postID: this.post.id, bid: bid.bid})
      this.fetchPost(this.post.id)
      this.openPopup()
    },
    openPopup() {
      const mobile = document.documentElement.clientWidth <= 480
      let modalHeight = mobile ? {height: '100%'} : {height: 400}
      this.$modal.hide('AuctionPopup')
      this.$modal.display(
        Popup,
        {post: this.post},
        {
          name: 'ApproveBidPopup',
          transition: 'none',
          overlayTransition: 'none',
          ...modalHeight,
          width: 500,
          classes: 'modal modal-auction'
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
