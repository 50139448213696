<template>
  <div :id="'record'" class="record__wrapper">
    <div class="record__header">
      <div class="record__icon" :class="{'record__icon--active': isRecording}" @click="startRecord">
        <i class="ri-mic-fill"></i>
      </div>
      <div class="record__duration">{{ moment.utc(duration * 1000).format('m:ss') }}</div>
    </div>
    <div class="record__buttons">
      <button v-if="isRecording" class="button button--rounded button--blue" @click="stopRecord">
        <i class="ri-pause-circle-fill"></i>
        <span>Stop recording</span>
      </button>
      <button v-if="audioUrl && !isPlaying" class="button button--rounded button--blue" @click="playRecord">
        <i class="ri-play-circle-fill"></i>
        <span>Play</span>
      </button>
      <button v-if="audioUrl && isPlaying" class="button button--rounded button--blue" @click="pauseRecord">
        <i class="ri-pause-circle-fill"></i>
        <span>Pause</span>
      </button>
      <button v-if="audioUrl" class="button button--rounded button--red" @click="removeRecord">
        <i class="ri-close-circle-fill"></i>
        <span>Remove and try again</span>
      </button>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'NewPostAudio',
  data() {
    return {
      moment,
      stream: null,
      mediaRecorder: null,
      audioChunks: [],
      audioBlob: null,
      audioUrl: null,
      audio: null,
      isRecording: false,
      isPlaying: false,
      duration: 0
    }
  },
  methods: {
    async record() {
      // TODO: CHANGE THIS TO 'audio/mp4' IF ON SAFARI!!!
      const ua = navigator.userAgent.toLowerCase()
      const isSafari = ua.indexOf('safari') != -1 && ua.indexOf('chrome') === -1

      const mimeType = isSafari ? 'audio/mp4' : 'audio/webm'

      this.stream = await navigator.mediaDevices.getUserMedia({audio: true})

      this.mediaRecorder = new MediaRecorder(this.stream, {mimeType})
      this.mediaRecorder.addEventListener('dataavailable', event => {
        this.audioChunks.push(event.data)
      })
      this.mediaRecorder.addEventListener('stop', async () => {
        this.audioBlob = new Blob(this.audioChunks, {type: mimeType})
        this.$emit('changeAudio', this.audioBlob)
        this.audioUrl = URL.createObjectURL(this.audioBlob)
        this.audio = new Audio(this.audioUrl)
      })
    },
    async startRecord() {
      if (this.isRecording) return
      try {
        await this.record()
        await this.mediaRecorder.start()
        this.durationTimer = setInterval(() => {
          this.duration++
        }, 1000)
        this.isRecording = true
      } catch (err) {
        alert(err)
      }
    },
    async stopRecord() {
      if (!this.isRecording) return
      await this.mediaRecorder.stop()
      await this.stream.getTracks()[0].stop()
      clearInterval(this.durationTimer)
      this.isRecording = false
    },
    playRecord() {
      this.isPlaying = true
      this.audio.play()
      this.audio.onended = () => {
        this.isPlaying = false
      }
    },
    pauseRecord() {
      this.audio.pause()
      this.isPlaying = false
    },
    removeRecord() {
      this.stream = this.mediaRecorder = this.audioBlob = this.audioUrl = null
      this.audioChunks = []
      this.duration = 0
      this.$emit('changeAudio', null)
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
