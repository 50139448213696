var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "popup__wrapper" }, [
    _c("div", { staticClass: "popup__header" }, [
      _c("div", { staticClass: "popup__header-title" }, [
        _vm._v("Bids Activity")
      ]),
      _c(
        "button",
        {
          staticClass: "icon-button icon-button--round",
          on: {
            click: function($event) {
              return _vm.$modal.hide("AuctionPopup")
            }
          }
        },
        [_c("i", { staticClass: "ri-close-circle-fill" })]
      )
    ]),
    _c("div", { staticClass: "bids__wrapper" }, [
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "bids__skeleton" },
            _vm._l(3, function(i) {
              return _c("Skeleton", { key: i, attrs: { height: "73px" } })
            }),
            1
          )
        : _c(
            "div",
            { staticClass: "bids__list" },
            _vm._l(_vm.bids, function(bid) {
              return _c("div", { key: bid.id, staticClass: "bids__item" }, [
                _c("div", { staticClass: "bids__date is-mobile" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.moment(bid.createdAt).format("DD MMM HH:mm")) +
                      " "
                  )
                ]),
                _c("div", { staticClass: "bids__main" }, [
                  _c("div", { staticClass: "bids__creator" }, [
                    _c("span", [_vm._v("Bid placed by ")]),
                    _c("span", [_vm._v(_vm._s(bid.authorBusinessName) + " ")]),
                    bid.authorNickName
                      ? _c("span", [
                          _vm._v("(" + _vm._s(bid.authorNickName) + ") ")
                        ])
                      : _vm._e(),
                    bid.authorFathersName
                      ? _c("span", [
                          _vm._v(_vm._s(bid.authorFathersName) + "’ ")
                        ])
                      : _vm._e(),
                    _c("span", [_vm._v(_vm._s(bid.authorFirstName) + " ")]),
                    _c("span", [_vm._v(_vm._s(bid.authorMiddleInitial) + " ")]),
                    _c("span", [_vm._v(_vm._s(bid.authorLastName) + " ")])
                  ]),
                  _c("div", { staticClass: "bids__date" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.moment(bid.createdAt).format("DD MMM HH:mm")
                        ) +
                        " "
                    )
                  ])
                ]),
                _c("div", { staticClass: "bids__price" }, [
                  _vm._v("$ " + _vm._s(bid.bid))
                ]),
                _c(
                  "button",
                  {
                    staticClass: "button button--green",
                    class: {
                      "button--green--active": bid.isApproved,
                      "button--green--disabled": _vm.post.postType === "sold"
                    },
                    on: {
                      click: function($event) {
                        return _vm.approve(bid)
                      }
                    }
                  },
                  [
                    _vm.post.postType === "sold" && bid.isApproved
                      ? _c("span", [_vm._v("Approved")])
                      : _c("span", [_vm._v("Approve")])
                  ]
                )
              ])
            }),
            0
          )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }