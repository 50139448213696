var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "record__wrapper", attrs: { id: "record" } },
    [
      _c("div", { staticClass: "record__header" }, [
        _c(
          "div",
          {
            staticClass: "record__icon",
            class: { "record__icon--active": _vm.isRecording },
            on: { click: _vm.startRecord }
          },
          [_c("i", { staticClass: "ri-mic-fill" })]
        ),
        _c("div", { staticClass: "record__duration" }, [
          _vm._v(_vm._s(_vm.moment.utc(_vm.duration * 1000).format("m:ss")))
        ])
      ]),
      _c("div", { staticClass: "record__buttons" }, [
        _vm.isRecording
          ? _c(
              "button",
              {
                staticClass: "button button--rounded button--blue",
                on: { click: _vm.stopRecord }
              },
              [
                _c("i", { staticClass: "ri-pause-circle-fill" }),
                _c("span", [_vm._v("Stop recording")])
              ]
            )
          : _vm._e(),
        _vm.audioUrl && !_vm.isPlaying
          ? _c(
              "button",
              {
                staticClass: "button button--rounded button--blue",
                on: { click: _vm.playRecord }
              },
              [
                _c("i", { staticClass: "ri-play-circle-fill" }),
                _c("span", [_vm._v("Play")])
              ]
            )
          : _vm._e(),
        _vm.audioUrl && _vm.isPlaying
          ? _c(
              "button",
              {
                staticClass: "button button--rounded button--blue",
                on: { click: _vm.pauseRecord }
              },
              [
                _c("i", { staticClass: "ri-pause-circle-fill" }),
                _c("span", [_vm._v("Pause")])
              ]
            )
          : _vm._e(),
        _vm.audioUrl
          ? _c(
              "button",
              {
                staticClass: "button button--rounded button--red",
                on: { click: _vm.removeRecord }
              },
              [
                _c("i", { staticClass: "ri-close-circle-fill" }),
                _c("span", [_vm._v("Remove and try again")])
              ]
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }