<template>
  <div class="skeleton" :style="{height, width}">
    <div class="skeleton__inner"></div>
  </div>
</template>

<script>
export default {
  name: 'Skeleton',
  props: {
    
    // options: {
    //   type: Array,
    //   default: new Array(),
    //   required: true
    // },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '48px'
    }
  },
  data: () => ({})
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
