var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "skeleton",
      style: { height: _vm.height, width: _vm.width }
    },
    [_c("div", { staticClass: "skeleton__inner" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }