<template>
  <div class="modal__wrapper">
    <div class="modal__header">
      <div class="modal__header-title">
        <span v-if="type === 'text'">New Text/Image Post</span>
        <span v-else-if="type === 'audio'">New Audio Post</span>
      </div>
      <div class="modal__header-buttons">
        <button class="button button--rounded" @click="$modal.hide('NewPostModal')">Cancel</button>
        <button class="button button--fill button--rounded" @click="createPost">Publish</button>
      </div>
    </div>
    <div v-if="type === 'text'" class="modal__main">
      <textarea v-model="text" class="modal__textarea" placeholder="Write text here..."></textarea>
      <FilesUpload :file="file" :show-modal="wasPin" @changeFile="changeFile" />
    </div>
    <div v-if="type === 'audio'" class="modal__main">
      <Record @changeAudio="changeFile" />
    </div>
    <div class="modal__footer">
      <div class="modal__footer-part">
        <div class="modal__footer-title">Type of Post</div>
        <div class="modal__footer-buttons">
          <button
            class="modal__footer-button is-blue"
            :class="{'is-active': postType === 'post'}"
            @click="postType = 'post'"
          >
            General
          </button>
          <button
            class="modal__footer-button is-yellow"
            :class="{'is-active': postType === 'question'}"
            @click="postType = 'question'"
          >
            Question
          </button>
          <button
            class="modal__footer-button is-red"
            :class="{'is-active': postType === 'forSale'}"
            @click="
              postType = 'forSale'
              wasPin = false
            "
          >
            For Sale
          </button>
        </div>
      </div>
      <div v-if="isBishop" class="modal__footer-part">
        <div class="modal__footer-part-header" :class="{'is-grey': !wasPin}">
          <div class="modal__footer-title">
            <div class="pin__icon">
              <i class="ri-pushpin-fill"></i>
            </div>
            <span>Pin post</span>
          </div>
          <el-switch v-model="wasPin" :disabled="postType === 'forSale' || type === 'audio'"> </el-switch>
        </div>
        <div v-if="wasPin" class="modal__footer-pins">
          <button
            class="modal__footer-pin is-blue"
            :class="{'is-active': pinType === 'default'}"
            @click="pinType = 'default'"
          >
            DEFAULT
          </button>
          <button
            class="modal__footer-pin is-red"
            :class="{'is-active': pinType === 'warning'}"
            @click="pinType = 'warning'"
          >
            WARNING
          </button>
          <button
            class="modal__footer-pin is-yellow"
            :class="{'is-active': pinType === 'important'}"
            @click="pinType = 'important'"
          >
            IMPORTANT
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FilesUpload from './FilesUpload'
import {mapActions, mapMutations, mapState} from 'vuex'
import Record from './NewPostAudio'

export default {
  name: 'NewPost',
  components: {FilesUpload, Record},
  props: {
    type: {
      type: String,
      default: null
    },
    post: {
      type: Object,
      default: null
    },
    target: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      postType: 'post',
      text: null,
      file: null,
      wasPin: false,
      pinType: 'default',
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      isBishop: s => s.auth.user.communityBishop
    })
  },
  created() {
    if (this.post) {
      this.postType = this.post.postType
      this.text = this.post.content
      this.pinType = this.post.pinType
      this.pinType ? (this.wasPin = true) : null
    }
  },
  methods: {
    ...mapActions({
      create: 'community/posts/create',
      update: 'community/posts/update',
      fetch: 'community/posts/fetch',
      fetchPinnedPosts: 'community/posts/fetchPinnedPosts',
      fetchPost: 'community/posts/fetchPost'
    }),
    ...mapMutations({
      reset: 'community/posts/reset',
      resetPinnedPosts: 'community/posts/resetPinnedPosts'
    }),
    changeFile(file) {
      this.file = file
    },
    async createPost() {
      if (this.isLoading) return
      if (
        (this.type === 'text' && !this.text) ||
        (this.type === 'audio' && !this.file) ||
        (this.wasPin && !this.pinType)
      )
        return
      this.isLoading = true
      let needFormatted = 1
      const platform = this.howPlatform()
      const browser = this.howBrowser()
      if ((platform === 'Macintosh' && browser === 'safari') || platform === 'iPhone' || platform === 'iPad') {
        needFormatted = 0
      }
      const sendData = new FormData()
      sendData.append('postType', this.postType)
      sendData.append('content', this.text)
      sendData.append('needFormatted', needFormatted)
      this.file ? sendData.append('attachment', this.file) : null
      this.wasPin ? sendData.append('pinType', this.pinType) : null
      try {
        if (this.post) {
          await this.update({post: sendData, id: this.post.id})
        } else {
          await this.create(sendData)
        }
        this.$modal.hide('NewPostModal')
      } finally {
        if (this.wasPin) {
          this.resetPinnedPosts()
          this.fetchPinnedPosts()
        } else {
          this.reset()
          this.fetch()
        }
        if (this.target === 'postPage') {
          this.fetchPost(this.post.id)
        }
        this.isLoading = false
      }
    },
    howPlatform() {
      var userDeviceArray = [
        {device: 'Android', platform: /Android/},
        {device: 'iPhone', platform: /iPhone/},
        {device: 'iPad', platform: /iPad/},
        {device: 'Symbian', platform: /Symbian/},
        {device: 'Windows Phone', platform: /Windows Phone/},
        {device: 'Tablet OS', platform: /Tablet OS/},
        {device: 'Linux', platform: /Linux/},
        {device: 'Windows', platform: /Windows NT/},
        {device: 'Macintosh', platform: /Macintosh/}
      ]

      var platform = navigator.userAgent

      for (var i in userDeviceArray) {
        if (userDeviceArray[i].platform.test(platform)) {
          return userDeviceArray[i].device
        }
      }
      return 'Undefined' + platform
    },
    howBrowser() {
      return (function(agent) {
        switch (true) {
          case agent.indexOf('edge') > -1:
            return 'edge'
          case agent.indexOf('opr') > -1 && !!window.opr:
            return 'opera'
          case agent.indexOf('chrome') > -1 && !!window.chrome:
            return 'chrome'
          case agent.indexOf('trident') > -1:
            return 'ie'
          case agent.indexOf('firefox') > -1:
            return 'firefox'
          case agent.indexOf('safari') > -1:
            return 'safari'
          default:
            return 'other'
        }
      })(window.navigator.userAgent.toLowerCase())
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
