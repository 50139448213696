<template>
  <div class="player__wrapper" @click.stop>
    <div class="player__controls">
      <div class="player__play" @click="play">
        <i v-if="isPlaying" class="ri-pause-fill"></i>
        <i v-else class="ri-play-fill"></i>
      </div>
    </div>
    <div class="player__timeline" @click="changeTimeline">
      <div class="player__progress" :style="{width: progressWidth}"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AudioPlayer',
  props: {
    src: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      audio: null,
      isPlaying: false,
      checkProgress: null,
      progressWidth: '0%'
    }
  },
  created() {
    this.audio = new Audio(this.src)
  },
  methods: {
    play() {
      if (this.isPlaying) {
        this.isPlaying = false
        clearInterval(this.checkProgress)
        this.audio.pause()
      } else {
        this.isPlaying = true
        this.checkProgress = setInterval(() => {
          this.progressWidth = (this.audio.currentTime / this.audio.duration) * 100 + '%'
          if (this.audio.currentTime === this.audio.duration) {
            this.isPlaying = false
          }
        }, 100)
        this.audio.play()
      }
    },
    changeTimeline(e) {
      const timelineWidth = window.getComputedStyle(e.target).width
      const timeToSeek = (e.offsetX / parseInt(timelineWidth)) * this.audio.duration
      this.audio.currentTime = timeToSeek
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
