<template>
  <div class="popup__wrapper">
    <div class="popup__header">
      <div class="popup__header-title">
        <i class="ri-checkbox-circle-fill"></i>
        <span>Bid has been approved</span>
      </div>
      <button class="icon-button icon-button--round" @click="$modal.hide('ApproveBidPopup')">
        <i class="ri-close-circle-fill"></i>
      </button>
    </div>
    <div class="popup__main">
      <p class="popup__main-text">
        Bid by
        <span style="font-weight: 700;">
          <span>{{ post.authorBusinessName }}</span>
          <span v-if="post.authorNickName"> ({{ post.authorNickName }})</span>
          <span v-if="post.authorFathersName">{{ post.authorFathersName }}’ </span>
          <span>{{ post.authorFirstName }}</span>
          <span> {{ post.authorMiddleInitial }}</span>
          <span> {{ post.authorLastName }}</span>
        </span>
        has been successfully approved and your post is now marked as <span class="is-orange">Sold</span>. Just get in
        touch with the bidder and make a deal!
      </p>
      <p class="popup__main-text">
        If you don’t have contact information you might want to visit Community Directory to find it!
      </p>
      <div class="popup__main-buttons">
        <button class="button" @click="$modal.hide('ApproveBidPopup')">Ok, got it, thanks</button>
        <button class="button" @click="goDirectory">Visit Community Directory</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AproveBidPopup',
  props: {
    post: {
      type: Object,
      default: null
    }
  },
  methods: {
    goDirectory() {
      this.$modal.hide('ApproveBidPopup')
      this.$router.push('/directory')
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
