var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "popup__wrapper" }, [
    _c("div", { staticClass: "popup__header" }, [
      _vm._m(0),
      _c(
        "button",
        {
          staticClass: "icon-button icon-button--round",
          on: {
            click: function($event) {
              return _vm.$modal.hide("ApproveBidPopup")
            }
          }
        },
        [_c("i", { staticClass: "ri-close-circle-fill" })]
      )
    ]),
    _c("div", { staticClass: "popup__main" }, [
      _c("p", { staticClass: "popup__main-text" }, [
        _vm._v(" Bid by "),
        _c("span", { staticStyle: { "font-weight": "700" } }, [
          _c("span", [_vm._v(_vm._s(_vm.post.authorBusinessName))]),
          _vm.post.authorNickName
            ? _c("span", [_vm._v(" (" + _vm._s(_vm.post.authorNickName) + ")")])
            : _vm._e(),
          _vm.post.authorFathersName
            ? _c("span", [_vm._v(_vm._s(_vm.post.authorFathersName) + "’ ")])
            : _vm._e(),
          _c("span", [_vm._v(_vm._s(_vm.post.authorFirstName))]),
          _c("span", [_vm._v(" " + _vm._s(_vm.post.authorMiddleInitial))]),
          _c("span", [_vm._v(" " + _vm._s(_vm.post.authorLastName))])
        ]),
        _vm._v(
          " has been successfully approved and your post is now marked as "
        ),
        _c("span", { staticClass: "is-orange" }, [_vm._v("Sold")]),
        _vm._v(". Just get in touch with the bidder and make a deal! ")
      ]),
      _c("p", { staticClass: "popup__main-text" }, [
        _vm._v(
          " If you don’t have contact information you might want to visit Community Directory to find it! "
        )
      ]),
      _c("div", { staticClass: "popup__main-buttons" }, [
        _c(
          "button",
          {
            staticClass: "button",
            on: {
              click: function($event) {
                return _vm.$modal.hide("ApproveBidPopup")
              }
            }
          },
          [_vm._v("Ok, got it, thanks")]
        ),
        _c(
          "button",
          { staticClass: "button", on: { click: _vm.goDirectory } },
          [_vm._v("Visit Community Directory")]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "popup__header-title" }, [
      _c("i", { staticClass: "ri-checkbox-circle-fill" }),
      _c("span", [_vm._v("Bid has been approved")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }