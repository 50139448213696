<template>
  <div class="files__wrapper">
    <div v-if="showModal" class="files__modal">
      Image won’t be uploaded and displayed for pinned posts
    </div>
    <div class="files__inner">
      <div v-if="file" class="files__item">
        <span
          class="files__item-type"
          :class="{
            'files__item-type--jpeg': fileData.type === 'jpeg',
            'files__item-type--png': fileData.type === 'png'
          }"
        >
          {{ fileData.type }}
        </span>
        <div class="files__item-info">
          <div class="files__item-name">{{ $dots(fileData.name, 20, false) }}</div>
          <span class="files__item-date">{{ fileData.date }}</span>
          <div class="files__item-dot"></div>
          <span class="files__item-size">{{ fileData.size }} Mb</span>
        </div>
        <div class="icon-button icon-button--round" @click="del">
          <i class="ri-delete-bin-fill"></i>
        </div>
      </div>
      <label v-else id="dropbox" class="files__label">
        <input class="files__input" type="file" accept="image/*" @change="handleFileUpload" />
        <i class="ri-image-fill"></i>
        <span class="files__title">
          Upload Photo
        </span>
      </label>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'UploadFile',
  props: {
    file: {
      type: File,
      default: null
    },
    showModal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    fileData() {
      const file = this.file
      const fileInfo = {
        type: file.type.split('/')[1],
        name: file.name,
        size: (file.size / Math.pow(2, 20)).toFixed(1),
        date: `${moment().format('DD MMM, YYYY')} at ${moment().format('HH:mm')}`
      }
      return fileInfo
    }
  },
  mounted() {
    this.drop()
  },
  methods: {
    handleFileUpload(e) {
      this.$emit('changeFile', e.target.files[0])
    },
    drop() {
      const dropbox = document.getElementById('dropbox')
      dropbox.addEventListener('dragenter', dragenter, false)
      dropbox.addEventListener('dragover', dragover, false)
      dropbox.addEventListener('drop', drop, false)
      function dragenter(e) {
        e.stopPropagation()
        e.preventDefault()
      }
      function dragover(e) {
        e.stopPropagation()
        e.preventDefault()
      }
      function drop(e) {
        e.stopPropagation()
        e.preventDefault()
        this.$emit('changeFile', e.target.files[0])
      }
    },
    del() {
      this.$emit('changeFile', null)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';
@import './style.scss';
</style>
