var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal__wrapper" }, [
    _c("div", { staticClass: "modal__header" }, [
      _c("div", { staticClass: "modal__header-title" }, [
        _vm.type === "text"
          ? _c("span", [_vm._v("New Text/Image Post")])
          : _vm.type === "audio"
          ? _c("span", [_vm._v("New Audio Post")])
          : _vm._e()
      ]),
      _c("div", { staticClass: "modal__header-buttons" }, [
        _c(
          "button",
          {
            staticClass: "button button--rounded",
            on: {
              click: function($event) {
                return _vm.$modal.hide("NewPostModal")
              }
            }
          },
          [_vm._v("Cancel")]
        ),
        _c(
          "button",
          {
            staticClass: "button button--fill button--rounded",
            on: { click: _vm.createPost }
          },
          [_vm._v("Publish")]
        )
      ])
    ]),
    _vm.type === "text"
      ? _c(
          "div",
          { staticClass: "modal__main" },
          [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.text,
                  expression: "text"
                }
              ],
              staticClass: "modal__textarea",
              attrs: { placeholder: "Write text here..." },
              domProps: { value: _vm.text },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.text = $event.target.value
                }
              }
            }),
            _c("FilesUpload", {
              attrs: { file: _vm.file, "show-modal": _vm.wasPin },
              on: { changeFile: _vm.changeFile }
            })
          ],
          1
        )
      : _vm._e(),
    _vm.type === "audio"
      ? _c(
          "div",
          { staticClass: "modal__main" },
          [_c("Record", { on: { changeAudio: _vm.changeFile } })],
          1
        )
      : _vm._e(),
    _c("div", { staticClass: "modal__footer" }, [
      _c("div", { staticClass: "modal__footer-part" }, [
        _c("div", { staticClass: "modal__footer-title" }, [
          _vm._v("Type of Post")
        ]),
        _c("div", { staticClass: "modal__footer-buttons" }, [
          _c(
            "button",
            {
              staticClass: "modal__footer-button is-blue",
              class: { "is-active": _vm.postType === "post" },
              on: {
                click: function($event) {
                  _vm.postType = "post"
                }
              }
            },
            [_vm._v(" General ")]
          ),
          _c(
            "button",
            {
              staticClass: "modal__footer-button is-yellow",
              class: { "is-active": _vm.postType === "question" },
              on: {
                click: function($event) {
                  _vm.postType = "question"
                }
              }
            },
            [_vm._v(" Question ")]
          ),
          _c(
            "button",
            {
              staticClass: "modal__footer-button is-red",
              class: { "is-active": _vm.postType === "forSale" },
              on: {
                click: function($event) {
                  _vm.postType = "forSale"
                  _vm.wasPin = false
                }
              }
            },
            [_vm._v(" For Sale ")]
          )
        ])
      ]),
      _vm.isBishop
        ? _c("div", { staticClass: "modal__footer-part" }, [
            _c(
              "div",
              {
                staticClass: "modal__footer-part-header",
                class: { "is-grey": !_vm.wasPin }
              },
              [
                _vm._m(0),
                _c("el-switch", {
                  attrs: {
                    disabled: _vm.postType === "forSale" || _vm.type === "audio"
                  },
                  model: {
                    value: _vm.wasPin,
                    callback: function($$v) {
                      _vm.wasPin = $$v
                    },
                    expression: "wasPin"
                  }
                })
              ],
              1
            ),
            _vm.wasPin
              ? _c("div", { staticClass: "modal__footer-pins" }, [
                  _c(
                    "button",
                    {
                      staticClass: "modal__footer-pin is-blue",
                      class: { "is-active": _vm.pinType === "default" },
                      on: {
                        click: function($event) {
                          _vm.pinType = "default"
                        }
                      }
                    },
                    [_vm._v(" DEFAULT ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "modal__footer-pin is-red",
                      class: { "is-active": _vm.pinType === "warning" },
                      on: {
                        click: function($event) {
                          _vm.pinType = "warning"
                        }
                      }
                    },
                    [_vm._v(" WARNING ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "modal__footer-pin is-yellow",
                      class: { "is-active": _vm.pinType === "important" },
                      on: {
                        click: function($event) {
                          _vm.pinType = "important"
                        }
                      }
                    },
                    [_vm._v(" IMPORTANT ")]
                  )
                ])
              : _vm._e()
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal__footer-title" }, [
      _c("div", { staticClass: "pin__icon" }, [
        _c("i", { staticClass: "ri-pushpin-fill" })
      ]),
      _c("span", [_vm._v("Pin post")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }