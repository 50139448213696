var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "files__wrapper" }, [
    _vm.showModal
      ? _c("div", { staticClass: "files__modal" }, [
          _vm._v(" Image won’t be uploaded and displayed for pinned posts ")
        ])
      : _vm._e(),
    _c("div", { staticClass: "files__inner" }, [
      _vm.file
        ? _c("div", { staticClass: "files__item" }, [
            _c(
              "span",
              {
                staticClass: "files__item-type",
                class: {
                  "files__item-type--jpeg": _vm.fileData.type === "jpeg",
                  "files__item-type--png": _vm.fileData.type === "png"
                }
              },
              [_vm._v(" " + _vm._s(_vm.fileData.type) + " ")]
            ),
            _c("div", { staticClass: "files__item-info" }, [
              _c("div", { staticClass: "files__item-name" }, [
                _vm._v(_vm._s(_vm.$dots(_vm.fileData.name, 20, false)))
              ]),
              _c("span", { staticClass: "files__item-date" }, [
                _vm._v(_vm._s(_vm.fileData.date))
              ]),
              _c("div", { staticClass: "files__item-dot" }),
              _c("span", { staticClass: "files__item-size" }, [
                _vm._v(_vm._s(_vm.fileData.size) + " Mb")
              ])
            ]),
            _c(
              "div",
              {
                staticClass: "icon-button icon-button--round",
                on: { click: _vm.del }
              },
              [_c("i", { staticClass: "ri-delete-bin-fill" })]
            )
          ])
        : _c(
            "label",
            { staticClass: "files__label", attrs: { id: "dropbox" } },
            [
              _c("input", {
                staticClass: "files__input",
                attrs: { type: "file", accept: "image/*" },
                on: { change: _vm.handleFileUpload }
              }),
              _c("i", { staticClass: "ri-image-fill" }),
              _c("span", { staticClass: "files__title" }, [
                _vm._v(" Upload Photo ")
              ])
            ]
          )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }